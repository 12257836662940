@import "src/vars";

.categoryCardList
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(5rem, 1fr));
    text-align: center;
    gap: 0.2rem;
}

@media (max-width: $breakpoint-tablet) {
    .categoryCardList
    {
        grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(4rem, 1fr));
        gap: 0.5rem;
    }
}

.categoryClearContainer
{
    margin-top: -2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    text-align: center;
}

.soundPlayerContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
    height:4rem;
    margin: 0.3rem 0.3rem 0.5rem auto;

    audio
    {
        height: 100%;
    }
}
@import "src/vars";

.soundCard
{
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
    width: 20rem;
    height:10rem;
    background-color: var(--component-background-color);
    border-radius: 0.5rem;
    transition: all 0.1s;
    border: 1px solid var(--card-border-color);
}

.soundCard:hover
{
   margin-top: -4px;
   box-shadow: var(--hover-default-box-shadow);
}

.soundCard:active 
{
    margin-top: 0;
}

.soundCardHeaderContainer
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.soundCardHeader 
{
    font-size: large;
    color: var(--primary-text-color);
}

.tooltip
{
    display: none;
}

.tooltipContainer
{
    position: relative;
    color: white;
    overflow: visible;
    z-index: 2;

    .tooltip
    {
        padding: 0.5rem;
        background-color: rgba(#222, 0.9);
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 0;
    }

    b
    {
        padding: 0.3rem;
    }

    .showTooltipButton
    {
        float: left;
    }

    .playInBrowserButton
    {
        float: right;
        font-size: 2.5rem;
    }
}

@media (max-width: $breakpoint-laptop) {
    .soundCard
    {
        width: 15rem;
        height: 7rem;
    }

    .soundCardHeader
    {
        font-size: 14px;
    }

    .tooltipContainer
    {
        font-size: 1rem;
    }
}

@media (max-width: $breakpoint-tablet) {
    .soundCard
    {
        width: 11.4rem;
        height: 6rem;
    }

    .soundCardHeader
    {
        font-size: 12px;
    }
}

.navigationBackground 
{
    
}

.mainNav 
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 5.5rem;
    width: 100%;
    justify-self: center;
    border-bottom: var(--primary-text-color) solid 1px;
    height:7vh;
    color: var(--primary-text-color);
    letter-spacing: 0.5em;
    background-color: var(--component-background-color);
}

.logoContainer
{
    height: 100%;
    display: flex;
    align-items: center;
}

.logo
{
    height: 100%;
}

a
{
    text-decoration: none;
    color: white;
    font-size: 2rem;
    transition: 1s all;
}

@media only screen and (max-width: 60rem) 
{
    .mainNav
    {
        font-size: 1.2rem;
        padding: 0;
    }

    a
    {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 40rem)
{
    a
    {
        font-size: 1rem;
    }
}

li 
{
    padding: 3rem;
    list-style-type: none;
    color: white;
}


a:hover{
    text-decoration: underline;
}


ul h1 
{
    height: inherit;
    font-size: 5rem;
    color: white;
}
@import "src/vars";

.categoryLabelContainer
{
    z-index: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 3rem;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

.categoryLabel
{
    flex: 1;
    padding: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
    width:0;
    overflow: hidden;
    position: relative;
}

.selectedCategory
{
    flex-grow: 3;
}

.notSelectedOverlay
{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.categoryLabelContainer > .categoryLabel:first-child
{
    border-top-left-radius: inherit;
}
.categoryLabelContainer > .categoryLabel:last-child
{
    border-top-right-radius: inherit;
}

@media (max-width: $breakpoint-laptop) {
    .categoryLabelContainer
    {
        height: 2.2rem;
    }
    .categoryLabel
    {
        font-size: medium;
        padding: 0.5rem;
    }
}

@media (max-width: $breakpoint-tablet) {
    .categoryLabelContainer
    {
        height: 1.5rem;
    }
    .categoryLabel
    {
        font-size: small;
        padding: 0.1rem;
    }
}
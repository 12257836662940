//Colour pallet for the project.
:root
{
    --background-color: #1D1D1F;
    --component-background-color: #353532;
    --primary-text-color: #D7DADC;
    --card-border-color: #343536;
  
    --color-primary-light: #368faa;
    --color-primary: #a31773;
    --color-primary-dark: #20547b;

    --color-grey-light-1: #faf9f9;
    --color-grey-light-2: #f4f2f2;
    --color-grey-light-3: #f0eeee;
    --color-grey-light-4: #ccc;

    --color-puple-light-1: #cab2fb;
    --color-puple-light-2:#7e61ab;
    --color-puple-light-3:#9656ce;

    --color-purple-dark-1: #5b209a;
    --color-purple-dark-2: #36135a;

    --color-grey-dark-1: #333;
    --color-grey-dark-2: #777;
    --color-grey-dark-3: #999;

    --color-transparent-white:rgba(255,255,255,0.5);

    --color-form-background: rgba(255,255,255, .9);;

    --shadow-dark: 0 2rem 6rem rgba(0,0,0,.3);
    --shadow-light: 0 1rem 2rem rgba(0,0,0, .1);

    --button-hover-shadow: 0 1rem 2rem rgba(255,255,255,0.2);
    --button-click-shadow: 0 .5rem 1rem rgba(255,255,255,0.2);

    --hover-default-box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2), 0 0.3rem 1rem 0 rgba(0, 0, 0, 0.19);
}

::selection
{
    background-color: var(--color-primary-light);
    color: white;
}

/*Universal selector selects every object*/
*,
*::after,
*::before 
{
    margin: 0;
    padding: 0;
    /*This means that every element will inherit the box-sizing from body, where this value is defined.*/
    box-sizing: inherit;
}

html 
{
    /*Setting this to a percentage of the font size given by the browser (browser default is 16px)*/
    /*When using rem-units in the css, they will be calculated by this font-size value.*/
    /*Using percentages instead of hard coded pixels so that user (people visiting the site) defined pixel sizes work.*/

    font-size: 62.5%;
    box-sizing: border-box;
}

body 
{
    margin: auto 0;
    font-size: 1.6rem;
    line-height: 1.6rem;
    font-family: "Times New Roman", Times, serif;
    background-color: var(--background-color);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
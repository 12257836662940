@import "src/vars";

.cardList
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr));
    text-align: center;
    gap: 2rem;
    margin-top: 1.5rem;
}

@media (max-width: $breakpoint-laptop) {
    .cardList
    {
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(7rem, 1fr));
        gap: 1.5rem;
    }
}

@media (max-width: $breakpoint-tablet) {
    .cardList
    {
        grid-template-columns: repeat(auto-fill, minmax(11.4rem, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(6rem, 1fr));
        gap: 1rem;
    }
}
.signInFormDiv 
{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    padding: 1rem;

    justify-self: center;
    animation-name: moveInLeft;
    animation-duration: 1s; 
}

.signInForm
{
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 1em;
    border: 1px solid white;
    display:grid;
    justify-content: center;
    align-items: center;
    width: 30em;
    height:40em;
    color:white;
}

@keyframes moveInLeft
{
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}
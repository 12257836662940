@import "src/vars";

.addAClip
{
    display: flex;
    margin: 2em 0;
}

.addClipForm
{
    color:white;
    flex: 1;
    flex-direction: column;

    form
    {
        max-width: 450px;
    }

    label
    {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
    }

    input
    {
        margin: 0.5rem 0;
        padding: 0.5rem;
    }


    .categoryMultiSelect
    {
        color:black;
    }

    audio
    {
        margin-top: 3rem;
    }

    .submitClip
    {
        margin-right: 2rem;
    }

    .formError
    {
        color: red;
        padding-bottom: 0.5rem;
    }
}

.youtubeContainer
{
    flex:1;
    position: relative;
    iframe
    {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-height: 50vh;

    }
}
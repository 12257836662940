@import "src/vars";

.sortSearchContainer
{
    display: flex;
    margin: 2em 0;
}

.dashboardSort
{
    align-items: center;
    width: 33%;
}

.dashboardSearch
{
    align-self: center;
    justify-self: center;
    width: 33%;
}

.dashboardOptions
{
    width: 33%;
    display: flex;
    color: white;
    flex-direction: row-reverse;
}

.orderSelect
{
    background-color: var(--component-background-color);
    color: white;
    height: 100%;
    margin-right: 1rem;
}

@media (max-width: $breakpoint-tablet) {
    .dashboardSort
    {
        width: 50%;
    }

    .dashboardSearch
    {
        width: 50%;
    }
}
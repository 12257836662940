@import "src/vars";

.categoryCard
{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 15rem;
    height:5rem;
    background-color: var(--color-grey-light-1);
    border-radius: 0.5rem;
    margin: 0.3rem;
    transition: all 0.1s;
    border: 1px solid var(--card-border-color);

    &:hover
    {
        transform: translateY(-4px);
        box-shadow: var(--hover-default-box-shadow);
    }

    &:active
    {
        transform: translateY(1px);
    }

    &.selectedCard
    {
        box-shadow: inset 0 0 50px #fff, 0 0 8px #fff;
        border: 3px solid #fff;
        transform: scale(0.98)
    }

}

@media (max-width: $breakpoint-tablet) {
    .categoryCard {
        height: 4rem;
        width: 11rem;
        h3 {
            font-size: 1.5rem;
        }
    }
}

.categoryCardHeader
{
    font-size: medium;
}
